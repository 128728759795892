//site
$primary-h: 232 !default;
$primary-s: 54 !default;
$primary-b: 41 !default;

$primary: #303f9f;
//$tertiary: #fed505;
//$tertiary-text: $white;

//$header-bg-color: $monet-blue;
//$header-center-bg-color: $header-bg-color;

//$headings-color: $primary;
//$semibold: 600;
